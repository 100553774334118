@import "bootstrap/scss/bootstrap";
@import "jspreadsheet-ce/dist/jexcel.css";
@import "jsuites/dist/jsuites.css";
@import "@fontsource/open-sans/400.css";
@import "@fontsource/source-code-pro/400.css";

body {
    font-family: 'Open Sans', monospace;
    margin: 10px;
}

.content {
    margin: 0 auto;
    max-width: 100%;
}

h1 { font-size: 1.5rem; }
h2 { font-size: 1rem; }

.feature-list {
    width: 752px;
    columns: 2;
}

.dataentry {
    min-width: 990px;
    details {
        summary {
            font-size: 1.25rem;
        }
    }
}

.download {
    min-width: 990px;
}

#accounts, #contacts, #modifications {
    font-family: 'Source Code Pro', monospace;
}

#accounts .jexcel>thead>tr>td, #contacts .jexcel>thead>tr>td {
    padding-left: 4px;
    padding-right: 4px;
}

.footer {
    font-size:0.9em;
}

.disclosure {
    details > summary {
        color: $link-color;
        text-decoration: $link-decoration;
        list-style: none;
    }
    details > summary::-webkit-details-marker {
        display: none;
    }
}
